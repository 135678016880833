import Vue from 'vue';
import VueRouter from 'vue-router';

//Components
import LogIn from '../components/LogIn/LogIn';
import Home from '../components/Home/Home';
import Perfil from '../components/Perfil/Perfil';
import Error from '../components/Misc/Error';
import Forbidden from '../components/Misc/Forbidden';

import AdminProductos from '../components/Productos/index';
import ListaProductos from '../components/Productos/ListaProductos';

//Middleware
import VueRouteMiddleware from 'vue-route-middleware';
import Auth from './middelwares/auth';
import Guest from './middelwares/guest';
import Permisos from './middelwares/permisos';

Vue.use(VueRouter);

const routes = [
    { path: '/login', name: 'login', component: LogIn, meta: { middleware: Guest } },
    { path: '/', name: 'root', component: Home, meta: { middleware: [Auth, Permisos] } },
    { path: '/home', name: 'home', component: Home, meta: { middleware: [Auth, Permisos] } },
    { path: '/perfil', name: 'perfil', component: Perfil, meta: { middleware: [Auth] } },
    {
        path: '/productos',
        component: AdminProductos,
        meta: { middleware: [Auth, Permisos] },
        children: [
            { path: '', name: 'listProductos', component: ListaProductos }
        ]
    },
    { path: '/forbidden', name: 'forbidden', component: Forbidden, meta: { middleware: Auth } },
    { path: '*', name: 'error', component: Error, meta: { middleware: Auth } },
];

export const router = new VueRouter({
    mode: "history",
    routes
});

router.beforeEach(VueRouteMiddleware());