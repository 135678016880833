<template>
    <div>
        <b-card header-tag="header" >
            <template #header>
                <b-button class="float-left mr-3" @click="$router.go(-1)" variant="danger" size="sm">
                    <b-icon icon="arrow-left-short" font-scale="1"></b-icon>
                </b-button>
                <div class="float-left table-panel-title">
                    Productos <span v-if="!loading">[{{pagination.count}}]</span>
                </div>
                <b-button class="float-right mr-3" @click="displayFiltros = !displayFiltros" size="sm" variant="secondary">Filtros</b-button>
                <div class="clearfix"></div>
                <b-collapse id="collapse-4" v-model="displayFiltros" class="mt-4">
                    <div class="row">
                        <!-- KeyWord -->
                        <div class="col-sm-12 col-lg-4 mb-3">
                            <b-input-group prepend="Keyword" size='sm'>
                                <b-form-input
                                    type="text" 
                                    v-model.trim="filtros.keyword"
                                    class="form-control" 
                                    :disabled = "loading"
                                ></b-form-input>
                            </b-input-group>
                        </div>
                        <!-- Marca -->
                        <div class="col-sm-12 col-lg-4 mb-3">
                            <b-input-group prepend="Marca" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.marca"
                                    :options="marcas"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                    <template #first>
                                        <b-form-select-option value="">Todas</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- Sku -->
                        <div class="col-sm-12 col-lg-4 mb-3">
                            <b-input-group prepend="SKU" size='sm'>
                                <b-form-input
                                    type="text" 
                                    v-model.trim="filtros.sku"
                                    class="form-control" 
                                    :disabled = "loading"
                                ></b-form-input>
                            </b-input-group>
                        </div>

                        <!-- video -->
                        <div class="col-sm-6 col-lg-4 mb-3">
                            <b-input-group prepend="Videos" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.video"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- manual -->
                        <div class="col-sm-6 col-lg-4 mb-3">
                            <b-input-group prepend="Manual" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.manual"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>
                        <!-- codigo -->
                        <div class="col-sm-6 col-lg-4 mb-3">
                            <b-input-group prepend="Codigos" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.codigo"
                                    :options="booleanOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div>       

                        <!--Publicado Wc -->
                        <div class="col-sm-6 col-lg-6 mb-3">
                            <b-input-group prepend="Publicado Wc" size='sm'>
                                <b-form-select
                                    type="text" 
                                    v-model.trim="filtros.publicados_wc"
                                    :options="publiStatusOpts"
                                    class="form-control"  
                                    :disabled = "loading"
                                >
                                </b-form-select>
                            </b-input-group>
                        </div> 

                        <div class="col-sm-12">
                            <b-button class="float-right mb-2" size="sm" @click="aplicarFiltros()" variant="primary">
                                Aplicar
                            </b-button>
                        </div>
                    </div>                
                </b-collapse>
            </template>

            <div v-if="loading" class="d-flex justify-content-center mt-5">
                <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            <div v-else class="mb-3">
                <div>   
                    <b-button class="float-left mr-3" @click="selectAll()" variant="secondary" size="sm" v-if="!selected.toggleAll">
                        All
                    </b-button>
                    <b-button class="float-left mr-3" @click="selectNone()" variant="secondary" size="sm" v-if="selected.toggleAll">
                        None
                    </b-button>

                    <b-button class="float-right mr-3" @click="showModalSitesWc()" variant="primary" size="sm" :disabled="!selected.productos.length">
                        WC
                    </b-button>  
                </div>
                <div class="clearfix mb-3"></div>
                <div class="row item-row mb-2 mt-2 pointer" v-for="producto in productos" :key="`producto-${producto.id}`" v-bind:class="{ 'row-selected': checkSelectedProd(producto.id) }" @click="toggleSelectProducto(producto.id)">
                    <div class="col-sm-12 col-lg-2 mb-sm-2 text-center" >
                        <img 
                            :src="producto.mini_img" 
                            class="img-thumbnail" 
                            :alt="producto.titulo" 
                            style="max-width: 120px;"
                        >
                        <br>
                    </div> 
                    <div class="col-sm-12 col-lg-10">
                        <div class="row">
                            <!-- Datos Producto -->
                            <div class="col-sm-12 col-lg-3 datos-item mb-2">
                                <span class="mb-sm-2"><b>Marca: </b>{{ producto.marca }}</span><br>
                                <span class="mb-sm-2"><b>Modelo: </b>{{ producto.modelo }}</span><br>
                                <span class="mb-sm-2"><b>SKU: </b>{{ producto.sku }}</span><br>
                                <span class="mb-sm-2"><b>Precio Costo: </b>{{ producto.Articulos[0].ArticuloPrecio ?  producto.Articulos[0].ArticuloPrecio.costo_total_estimado : 'No Actualizado' }}</span><br>
                            </div>
                            <!-- Publicaciones WC -->
                            <div class="col-sm-12 col-lg-9 mb-2">
                                <b-card header="Sitios WC">
                                    <div v-for="sitio in sitiosWc.default" :key="`producto-${producto.id}-sitioWC-${sitio.id}`">
                                        <span>{{sitio.nombre}}</span>
                                        <span class="float-right" v-html="getEstadoPublicacionSitioWC(sitio.id,producto.ProductoPublicars)"></span>
                                    </div>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
                
                <b-pagination
                    v-model="pagination.page"
                    :total-rows="pagination.count"
                    :per-page="pagination.limit"
                    align="center"
                    first-number
                    last-number
                    @change="handlePageChange"
                ></b-pagination>

            </div>
        </b-card>

        <b-modal ref="modal-select-wc-sites" title="Seleccionar Sitios Wc" style="max-width: 60%;">
            <div v-if="sitiosWc.default.length">
                <div v-for="sitioWc in sitiosWc.default" :key="`sitio-wc-${sitioWc}`">
                    <span>{{sitioWc.nombre}} ({{sitioWc.pais}})</span>
                    <b-form-checkbox :checked="checkSelectedSiteWC(sitioWc)" :disabled="!sitioWc.active" @change="toggleSitioWcSelected($event,sitioWc)" switch class="mr-2" style="display: inline-block;float: left;"></b-form-checkbox>
                </div>
            </div>
            <template #modal-footer>
                <b-button
                    variant="danger"
                    size="sm"
                    class="float-left"
                    @click="closeModalSitesWc()"
                >
                    Cancelar
                </b-button>
                <b-button
                    variant="primary"
                    size="sm"
                    class="float-right"
                    @click="agregarSitiosWcPublicar()"
                >
                    Agregar
                </b-button>
            </template>
        </b-modal>
    </div>  
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            displayFiltros : false,
            booleanOpts: [{value: 'TODO', text: "Todos"},{value: false, text: "No"},{value: true, text: "Si"}],
            publiStatusOpts: [
                {value: 'TODO', text: "Todos"},
                {value: 'PUBLICADO', text: "Publicado"},
                {value: 'COLA', text: "En Cola"},
                {value: 'NO_PUBLICADO', text: "No Publicado"},
                // {value: 'ERROR', text: "Error"}
            ],
            marcas: [],
            filtros: {
                sku: '',
                marca: '',
                keyword: '',
                video: 'TODO',
                manual: 'TODO',
                codigo: 'TODO',
                publicados_wc:'TODO'
            },
            pagination:{
                page: 1,
                limit: 10,
                count: 0,
            },
            selected:{
                toggleAll: false,
                productos: []
            },
            productos: [],
            sitiosWc: {
                default: [],
                selected: []
            },
        }
    },
    methods: {
        showErrorModal(error){
            this.$swal.fire({
                icon: 'error',
                title: "ERROR!",
                text: error,
                timer: 5500
            })
        },
        async getListaProductos(){
            try {
                let query = `producto/publicar`+this.armarQuery(false);

                let response = await this.$http.get(query, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener productos');
                this.productos = response.body.data.rows;
                console.log('productos',this.productos)

                this.pagination.page = response.body.data.page;
                this.pagination.limit = response.body.data.limit;
                this.pagination.count = response.body.data.count;

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getListaMarcas(){
            try {
                let response = await this.$http.get(`producto/marca`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener lista de marcas');
                this.marcas = [];
                response.body.data.forEach(element => {
                    if(this.marcas.indexOf(element.marca) == -1) this.marcas.push(element.marca);
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        async getSitiosWc(){
            try {
                let response = await this.$http.get(`wc/site`, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                if (!response.body.success) throw new Error('Al obtener lista de sitios Wc');
                this.sitiosWc.default = response.body.data

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
        armarQuery(){
            let queryParams = {};
            queryParams.page = this.pagination.page;
            queryParams.limit = this.pagination.limit;
            if(this.filtros.sku != '')    queryParams.sku = this.filtros.sku;
            if(this.filtros.keyword != '')    queryParams.keyword = this.filtros.keyword;
            if(this.filtros.marca != '')    queryParams.marca = this.filtros.marca;
            if(this.filtros.video != 'TODO')    queryParams.video = this.filtros.video;
            if(this.filtros.manual != 'TODO')    queryParams.manual = this.filtros.manual;
            if(this.filtros.codigo != 'TODO')    queryParams.codigo = this.filtros.codigo;
            if(this.filtros.publicados_wc != 'TODO')    queryParams.publicados_wc = this.filtros.publicados_wc;

            let query = '';
            for( var key in queryParams ) {
                var value = queryParams[key];
                if(query == '') query+= '?'+key+'='+value;
                else query+= '&'+key+'='+value;
            }
            this.$router.push({ query: Object.assign({}, {}, queryParams) }).catch(() => {});
            return query;
        },
        limpiarFiltros(){
            this.filtros.sku = this.$route.query.sku || '';
            this.filtros.marca = this.$route.query.marca || '';
            this.filtros.keyword = this.$route.query.keyword || '';
            this.filtros.video = this.$route.query.video || 'TODO';
            this.filtros.manual = this.$route.query.manual || 'TODO';
            this.filtros.codigo = this.$route.query.codigo || 'TODO';
            this.filtros.publicados_wc = this.$route.query.publicados_wc || 'TODO';
        },
        checkSelectedProd(id_producto){
            if(this.selected.productos.indexOf(id_producto) != -1) return true;
            return false;
        },
        toggleSelectProducto(id_producto){
            let index = this.selected.productos.indexOf(id_producto);
            if(index == -1){
                this.selected.productos.push(id_producto);
            }else{
                this.selected.productos.splice(index, 1);
            }
        },
        async aplicarFiltros (){
            this.loading = true;
            this.pagination.page = 1;
            await this.getListaProductos();
            this.loading = false;
        },
        async handlePageChange(value){
            this.loading = true;
            this.pagination.page = value;
            this.armarQuery();
            await this.getListaProductos();
            this.loading = false;
        },
        selectAll(){
            this.productos.forEach(producto => {
                this.selected.productos.push(producto.id);
            });
        },
        selectNone(){
            this.selected.productos = [];
        },
        getEstadoPublicacionSitioWC(id_wc_site,ProductoPublicars){
            if(ProductoPublicars){
                let datosPubli = ProductoPublicars.find(element => element.id_wc_site == id_wc_site)
                if(datosPubli){
                    if(!datosPubli.status) return '<span style="color:#DC3545">No Publicado</span>';
                    if(datosPubli.error) return `<span style="color:#DC3545">Error (${datosPubli.try})</span>`;
                    if(datosPubli.id_publish) return '<span style="color:#218838">Publicado</span>';
                    return '<span style="color:#218838">En Cola</span>';
                }else{
                    return '<span style="color:#DC3545">No Publicado</span>';
                }
            }else{
                return '<span style="color:#DC3545">Sin Datos</span>';
            }
        },
        showModalSitesWc(){
            this.sitiosWc.selected = [];

            if(this.selected.productos.length){

                //En caso de heaber 1 solo producto muestro si tiene publicaciones activas
                if(this.selected.productos.length === 1){
                    let producto = this.productos.find(element => element.id === this.selected.productos[0])
                    if(producto.ProductoPublicars){
                        producto.ProductoPublicars.forEach(element => {
                            if(element.id_wc_site) this.sitiosWc.selected.push(element.id_wc_site)
                        });
                    }
                }

                //Si no tengo nada seleccionado, selecciono los default
                if(!this.sitiosWc.selected.length){
                    this.sitiosWc.default.forEach(sitio => {
                        if(sitio.default)  this.sitiosWc.selected.push(sitio.id)
                    });  
                }
                this.$refs['modal-select-wc-sites'].show()
            }
        },
        closeModalSitesWc(){
            this.$refs['modal-select-wc-sites'].hide()
        },
        checkSelectedSiteWC(sitioWc){
            if(sitioWc.active){
                if(this.sitiosWc.selected.indexOf(sitioWc.id) != -1) return true;
                if(sitioWc.default) return true;
                return false; 
            }else{
                return false; 
            }
            
        },
        toggleSitioWcSelected(value,sitioWc){
            let index = this.sitiosWc.selected.indexOf(sitioWc.id);
            if(value && index == -1){
                this.sitiosWc.selected.push(sitioWc.id)
            }
            if(!value && index != -1){
                this.sitiosWc.selected.splice(index, 1);
            }
        },
        async agregarSitiosWcPublicar(){
            try {
                await this.$http.post(`producto/wc/publicar`,{productos: this.selected.productos ,sitiosWc: this.sitiosWc.selected}, { headers: { 'Authorization': 'Bearer '+ this.$store.getters.getToken } });
                this.getListaProductos();
                this.closeModalSitesWc();
                this.$swal.fire({
                    icon: 'success',
                    title: "Productos enviados a cola publicar",
                    timer: 3500
                });

            } catch (error) {
                let message = error.message || error.body.err.message ||'Al conectar con el servidor';
                this.showErrorModal(message)
            }
        },
    },
    async beforeMount(){
        this.loading = true;

        this.pagination.page = this.$route.query.page || 1;
        this.pagination.limit = this.$route.query.limit || 10;
        
        this.limpiarFiltros();
        await this.getSitiosWc();
        await this.getListaProductos();
        this.getListaMarcas();
        
        this.loading = false;
    }
}
</script>

<style scoped>
    .item-row{
        border: 1px solid rgb(0 0 0 / 25%);
        padding: 10px 15px;
        border-radius: 5px;
    }

    .datos-item{
        font-size: small;
    }

    .row-selected{
        background-color: #b8daff;
    }
</style>